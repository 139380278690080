import React from 'react';
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Office from "../components/office"
import Header from "../components/header"
import Fade from "react-reveal/Fade";

const About = ({ data }) => {
  const noOffices = data.allStrapiOffices.edges.length;
  const lastRow = (number) => {
    if (number % 3 === 1 ) {
      return 'remainder1'
    } else if (number % 3 === 2) {
      return 'remainder2'
    } else {
      return 'remainderNone';
    }
  }

  return (
    <Layout>
      <Seo title="Yeti — About" />
      <Header />

      <div className="mb-10 sm:mb-14 md:mb-20 pt-32 md:pt-40 lg:pt-48">
        <img src={data.strapiAbout.yeti_image.publicURL} alt="yeti icon" 
          className="rounded-full 
                    w-8/12 md:w-1/2 lg:w-4/12 
                    mx-auto min-h-full"
          />
      </div>
      <Fade>
        <ReactMarkdown className="font-bold text-center
          text-2xl sm:text-4xl md:text-4.5xl xl:text-5.5xl
          w-11/12 lg:w-9/12 xl:w-4/5 max-w-5xl
          mx-auto
          gradient-text-wrap" 
          source={data.strapiAbout.description} 
        />
      </Fade>


      <hr className="divider-rule"></hr>

      <div 
        className="sm:grid sm:grid-cols-2 lg:grid-cols-3 
        md:gap-14 lg:gap-16 
        w-4/5 sm:w-11/12 md:w-9/12 xl:w-8/12 max-w-5xl
        mx-auto
        ">
        {data.strapiAbout.capability.map((capability, i) => (
          <Fade key={i} duration={500}>
            <div className="text-center mb-10 sm:mb-10 md:mb-0">
              <h2 className="text-orange font-bold text-2xl md:text-3xl capitalize pb-2 md:pb-4">{capability.capability_title}</h2>
              <p className="text-white text-base">{capability.capability_description}</p>
            </div>
          </Fade>
        ))}
      </div>

      <hr className="divider-rule"></hr>

      <div 
        className="w-8/12 sm:w-10/12 md:w-10/12 xl:w-8/12 max-w-5xl
        mx-auto
        sm:grid sm:grid-cols-4 lg:grid-cols-6 
        gap-y-12 sm:gap-14 md:gap-20">
                      
        {data.allStrapiOffices.edges.map(office => (
            <Office key={office.node.id} office={office} lastRow={lastRow(noOffices)}/>
        ))}
      </div>

      <hr className="divider-rule"></hr>

    </Layout>
  )
}

export default About

export const query = graphql`
  query {
    allStrapiOffices(filter: {abbreviation: {ne: "la"}}) {
      edges {
        node {
          abbreviation
          city_state
          contact_email
          contact_name
          phone_number
          id
          office_image {
            publicURL
          }
        }
      }
    }
    strapiAbout {
      capability {
        capability_description
        capability_title
      }
      yeti_image {
        publicURL
      }
      description
    }
  }
`