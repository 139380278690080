import * as React from "react"
import Fade from "react-reveal"

const Office = ({ office, lastRow }) => (
  <Fade>
    <div className={`${lastRow} text-white text-center col-span-2 mb-10 sm:mb-0`}>
      <div className="w-10/12 mx-auto">
        <div className="w-full h-0 relative overflow-hidden rounded-full mb-6" style={ {paddingTop: '100%'} }>
          <div className="absolute top-0 left-0 w-full h-full">
            <img src={office.node.office_image.publicURL} 
            className="object-cover w-full h-full"
            alt={`${office.node.city_state} office building`}/>
          </div>
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 font-black text-7xl opacity-60 uppercase">{office.node.abbreviation == 'bos' ? 'us' : office.node.abbreviation}</div>
        </div>
      </div>

      <p className="text-base pb-2 md:pb-4">{office.node.abbreviation == 'bos' ? 'US' : office.node.city_state}</p>
      <p className="font-medium text-orange text-2xl md:text-3xl pb-2 md:pb-4">{office.node.contact_name}</p>
      <a className="underline text-base block" href={`mailto:${office.node.contact_email}`}>{office.node.contact_email}</a> 
      <a href={`tel:${office.node.phone_number}`} className="underline text-base block">{office.node.phone_number}</a>
    </div>
  </Fade>
)

export default Office;